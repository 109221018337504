/* If you need to add @import statements, do so up here */

@import "jit-refresh.css"; /* triggers frontend rebuilds */

/* Set up Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@400&display=swap');

@import '~lightgallery/css/lightgallery.css';

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer utilities {
  @variants responsive {
    .add-sticky-padding-top {
      padding-top: 55px;
    }
    .toppad-homepage {
      padding-top: 68px;
    }
  }
}

:root {
  --green: #82937b;
  --light-green: #A7B4A1;
  --dark-green: #242623;
  --gold: #d3af5e;
  --light-gold: #AE8835;
  --dark-gold: #AE8835;
  --light-grey: #fff6ef;
  --mid-grey: #eddacc;
  --dark-grey: #ab866c;
  --body-background: var(--light-grey);
  --body-color: var(--dark-grey);  
}

body {
  background: var(--body-background);
  color: var(--body-color);
  @apply font-serif text-base;
}

a {
  color: var(--gold);
  text-decoration-line: underline;
  text-decoration-style: dotted;
}

a:hover {
  color: var(--dark-gold);
}

code, pre {
  @apply text-sm bg-green text-light-grey rounded-lg py-1 px-2;
}

pre {
  @apply mb-5;
}

p {
  color: var(--dark-grey);
  @apply pb-10 leading-normal text-xl;
}

h4 {
  @apply uppercase pb-5 font-sans text-base;
}

h3 {
  @apply text-4xl pb-5 mt-2 font-serif;
}

h2 {
  color: var(--green);
  @apply text-6xl pb-5 mt-2 font-serif;
}

h1 {
  color: var(--green);
  @apply text-4xl lg:text-7xl pb-12 font-serif;
}

ul {
  @apply mb-8 mt-2;
}

li {
  list-style-type: square;
  list-style-position: inside;
  @apply pb-2;
}

/* table styles */
table {
  @apply w-full;
}

th {
  @apply bg-dark-grey text-light-grey py-2 px-4;
}

td {
  @apply p-4;
}

tr:nth-child(even) {
  @apply bg-mid-grey;
}

hr {
    overflow: visible; /* For IE */
    padding: 0;
    border: none;
    border-top: medium double var(--dark-grey);
    color: var(--dark-grey);
    text-align: center;
}
hr:after {
    content: "❦";
    display: inline-block;
    position: relative;
    top: -0.5em;
    font-size: 1.5em;
    padding: 0 0.25em;
    @apply bg-light-grey;
}

/* custom ids */

#myHeader {
  position: sticky;
  top: 0;
}
/* custom classes */

.has-drop-cap:not(:focus):first-letter {
    float: left;
    font-size: 3.4em;
    line-height: .68;
    font-weight: 100;
    margin: .05em .1em 0 0;
    text-transform: uppercase;
    font-style:normal
}

.btn {
  @apply rounded-md py-2 px-4 uppercase text-sm;
}

.fullsticky {
  position: fixed;
  top: 0;
  width: 100%;
}

.mysticky {
  position: fixed;
  top: 0;
  width: 100%;
}

.mysticky + .default-main-content {
  padding-top: 68px;
}

.footer-list li {
  list-style-type: none;
  @apply pb-5;
}

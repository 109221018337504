.swiper-button-next,
.swiper-button-prev {
  color: var(--light-grey);
}

.transition-fade {
  transition: 0.4s;
  opacity: 1;
}

html.is-animating .transition-fade {
  opacity: 0;
}
